import React from "react";
import Layout from "../layouts/layout";
import Heading1 from "../components/rich-text-elements/heading-1";
import SiteSearch from '../components/site-search'

export default class SearchPage extends React.Component {
  render() {
    return (
      <Layout title="Site search" metaDescription={undefined} metaImageUrl={undefined} noIndex={true}>
        <div className="container px-6 mx-auto pt-32 pb-v-1/4">
          <Heading1>Search</Heading1>
          <SiteSearch/>
        </div>
      </Layout>
    );
  }
}
